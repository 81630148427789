.wrapper {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    justify-content: center;
    justify-items: center;
    background-color: #f8f9fa;
}

.swis-heading {
    color: #FF9900;
}

.katal {
    height: 100vh;
}

.qr-code {
    display: grid;
    justify-content: center;
    justify-items: center;
    flex-direction: column;
    /* flex-flow: wrap; */
    /* padding-bottom: 15px; */
    grid-gap: 13px;
}

.custom-card {
    // min-width: 30vw;
    background-color: #fbf8f8 !important;
    border: solid 1px #001219 !important;
    border-radius: 4px !important;
    height: max-content;
    margin-top: 22vh;
    width: 20vw;
    box-shadow: 1px 1px 1px 1px grey !important;
    z-index: 5;
    label {
        font-weight: 500;
        font-size: medium;
    }
}


.custom-qr-card {
    background-color: #fbf8f8 !important;
    border: solid 1px #001219 !important;
    border-radius: 4px !important;
    display: grid;
    justify-content: center;
    justify-items: center;
    min-width: 30vw;
    height: max-content;
    margin-top: 22vh;
    width: 20vw;
    box-shadow: 1px 1px 1px 1px grey !important;
    z-index: 5;
    label {
        font-weight: 500;
        font-size: medium;
    }
}
.form-grid {
    display: grid;
    grid-auto-rows: auto;
    grid-gap: 10px;
}

.form-wrapper {
    display: grid;
    grid-gap: 10px;
    justify-content: center;
    justify-items: center;
}

.sso-button {
    width: -webkit-fill-available;
}

.wrap-error {
    color: red;
    font-size: small;
    font-weight: bold;
}

.error-heading {
    color: red;
    font-size: medium;
    font-weight: 500;
}

.my-loader {
    width: 4rem;
    height: 4rem;
}

.main-wrapper {
    display: grid;
    height: 100vh;
    grid-template-rows: 1fr 10fr;
    background-color: #2A2B2A;
    font-family: Amazon Ember,Arial,sans-serif!important;
}

.header {
    /* display: inline-grid; */
    /* justify-items: left; */
    background-color: #001219;
    padding-top: 2vh;
    box-shadow: 0 4px 4px 0 grey;
    z-index: 15;
    padding-left: 2vw;
    display: inline-flex;
    flex-direction: row;
    gap: 10px;
    flex-basis: max-content;
}

.header-icon {
    font-size: 30px;
    color: #FF9900;
    padding-top: 6px;
}

.heading-superscript {
    color: #FF9900;
    margin-left: -8px;
    line-height: 54px;
}